.App {
  text-align: center;
}

.text-primary-blue {
  color: #001c64 !important;
}

.select-disabled {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.text-featured-title {
  color: #f69b00;
  font-size: 2rem;
}

.movie-card {
  height: 170px;
  transition: all 0.5s ease-in-out;
}

.card {
  border: none !important;
  overflow: hidden;
}

.carousel-image {
  z-index: 1;
  width: 55%;
  height: 500px;
}

.carousel-full-width {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.featured-description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.featured-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.featured-overlay:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
}

.carousel-caption {
  top: 25%;
}

.featured-thumbnail-container {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.featured-thumbnail-play {
  position: absolute;
  z-index: 99;
}

.inner_circle {
  fill: #0b5ed7;
  fill: #042885;
}

.play {
  fill: white;
}

.outer_circle {
  stroke-width: 3;
  stroke-dasharray: 410;
  stroke-dashoffset: 0;
  stroke-linecap: square;
  transition: all 0.4s ease-out;
}

.featured-thumbnail {
  transition: all 0.5s ease-in-out;
}

.featured-thumbnail-overlay::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-out;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
}

.featured-thumbnail-container:hover .movie-card {
  z-index: 1;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}

.featured-thumbnail-container:hover .featured-thumbnail {
  z-index: 1;
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  transition: all 0.5s ease-in-out;
}

.featured-thumbnail-container:hover .outer_circle {
  stroke-dashoffset: 410;
  transition: stroke 0.3s 0.4s ease-out, stroke-dashoffset 0.2s ease-out;
}

.featured-thumbnail-container:hover .inner_circle {
  fill: #bf2428;
  transition: fill 0.1s 0.1s ease-out;
}

.featured-thumbnail-container:hover .play {
  fill: white;
  transition: fill 0.4s 0.3s ease-out;
}

.featured-thumbnail-container:hover .featured-thumbnail-overlay::after {
  z-index: 2;
  opacity: 1;
  transition: all 0.3s ease-in;
}

.section-title::after {
  margin-top: 8px;
  content: '';
  width: 41px;
  height: 4px;
  background-image: linear-gradient(264deg, #f69b00, #ed6a00);
  display: block;
  border-radius: 2px;
}

.moviePlayer {
  height: auto;
}

.video-player {
  height: 500px;
  width: 100%;
}

.social-shares > * {
  margin: 5px;
}

.file {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 1em;
}

.pdf-icon {
  height: 36px !important;
  width: 50px !important;
  /* color: #001c64ad; */
  color: #bf1e1eab;
}

.audio-icon {
  height: 36px !important;
  width: 50px !important;
  color: #001c64ad;
  /* color: #bf1e1eab; */
}

.view-desc {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: 0.5s;
}

.view-desc-open {
  -webkit-line-clamp: none;
  transition: 0.5s;
}

.fade_rule {
  height: 1px;
  background-color: #e6e6e6;
  width: 80%;
  margin: 0 auto;
  background-image: linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
}

.bg-primary-blue {
  background-color: #042885 !important;
  border-color: #001c64 !important;
}

.bg-primary-blue:hover {
  background-color: #001c63 !important;
  border-color: #011444 !important;
}

.outline-primary-blue:hover {
  background-color: #001c63 !important;
  border-color: #011444 !important;
}

#pagination-item {
  color: #001c64 !important;
}

#pagination-item-active {
  background-color: #042885 !important;
  border-color: #001c64 !important;
}

.modal-body audio {
  width: 100%;
}

.view-downloads {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
  padding: 1.5em;
}

.footer-header-text {
  color: #f69b00 !important;
}

@media (max-width: 768px) {
  .movie-card {
    height: 120px;
  }

  .card-body {
    padding: 5px !important;
  }

  .movie-card-title {
    font-size: 1rem !important;
  }

  .movie-card-text {
    font-size: 0.9rem !important;
  }

  .featured-card {
    padding: 5px;
  }

  .featured-card-img {
    height: 180px;
  }

  .carousel-image {
    width: 100%;
    height: auto;
  }
  .carousel-caption {
    top: 1%;
  }

  .featured-description {
    -webkit-line-clamp: 2;
  }

  .carousel-inner {
    height: 70vh;
  }

  .featured-overlay {
    height: 70vh;
  }

  .featured-overlay-background {
    position: absolute;
    object-fit: cover;
    height: 70vh !important;
  }
}

@media (max-width: 992px) {
  .featured-row {
    flex-direction: column-reverse;
  }

  .featured-title {
    font-size: 1.3em !important;
  }

  .movie-card-text {
    font-size: 0.8em !important;
  }

  .video-player {
    height: 28vh;
  }

  .text-featured-title {
    color: #f69b00;
    /* color: #3a5088; */
    font-size: 1.4rem;
  }

  .movie-card-title {
    font-size: 1.2rem;
  }
}
